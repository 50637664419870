
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {ROUTES} from "@/data";
import moment, {Moment} from "moment-timezone";
import clonedeep from "lodash.clonedeep";
import {capitalize} from "@/helpers/commons";
import {Getter, State} from "vuex-class";

@Component({
    name: "Visualisations"
})
export default class Visualisations extends mixins(VModel) {

    $refs!: any;
    data:any = {
        candidates: ['list', 'grid', 'map'],
        jobs: ['list'],
    };
    opened = false;
    timeout:any = 0;

    capitalize = capitalize;

    @State('selectedView') selectedView!: any;
    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;
    @Getter('isArchives') isArchives!: boolean;

    @Watch('innerValue.view')
    onView() {
        if (this.data[this.innerValue.view]?.indexOf(this.innerValue.mode) === -1) {
            this.innerValue.mode = this.data[this.innerValue.view][0];
        }
    }

    @Watch('selectedView')
    onSelectedView() {
        if (this.selectedView) {
            this.innerValue.view = this.selectedView.view;
            this.innerValue.mode = this.selectedView.mode;
        }
    }

    get isCandidates() {
        return this.$route.name === ROUTES.ATS.CV.LIST;
    }

    get hasJobs() {
        return this.isCandidates;
    }

    get hasCandidates() {
        return this.isCandidates;
    }

    get hasJobsActive() {
        return true;
    }

    get hasList() {
        return this.isCandidates;
    }
    get hasGrid() {
        return this.isCandidates
    }
    get hasMap() {
        return this.isCandidates
    }

}
