
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { StoreAction } from "@/types";
import { capitalize } from "@/helpers/commons";
import moment from "moment-timezone";
import Visualisations from "@/components/ats/Visualisations.vue";
import CandidateAvailableCommands from "@/components/ats/Candidate/CandidateAvailableCommands.vue";
import { CandidateStatus, ROUTES } from "@/data";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import PaginationServer from "@/components/PaginationServer.vue";

@Component({
    name: "List",
    components: { CandidateAvailableCommands, Visualisations, PaginationServer },
})
export default class List extends mixins(TitleManager) {
    @Prop() filters!: any;

    pageTitle = this.$t("page.candidates.list.title");
    loading = false;
    candidates: any = [];
    columns: any = [];
    showMenu = [];
    pagination: any = {
        currentPage: 0,
        offset: 0,
        limit: 50,
        total: 0,
    };
    timeout: any = 0;
    selectedCandidates = [];
    visualisations: any = {
        view: "candidates",
        mode: "list",
    };
    sortModifiers: { field: string; type: string } | undefined;

    @Action("actions/getCandidates") getCandidates!: StoreAction;

    @Getter("isSupervisor") isSupervisor!: boolean;

    @Watch("filters", { deep: true })
    onFilters() {
        this.init();
    }

    get hasCandidatesSelected() {
        return this.selectedCandidates.length;
    }

    get headers() {
        return [
            {
                field: "_id",
                hidden: true,
            },
            {
                field: "workerId",
                hidden: true,
            },
            {
                field: "__actions",
                hidden: false,
                sortable: false,
            },
            {
                field: "availableCommands",
                hidden: true,
            },
            ...this.columns.map((column: any) => {
                return {
                    label: this.$t("page.candidates.table." + column.id),
                    field: column.id,
                    sortable: column.sortable,
                    formatFn: this.formatCell(column.id),
                    filterOptions: {
                        enable: column.id !== "status" && column.filterable,
                    },
                };
            }),
        ];
    }

    isDateCell(key: string) {
        return ["date"].indexOf(key) !== -1;
    }

    formatCell(key: string) {
        return (value: any) => {
            if (key === "firstname" || key === "lastname") {
                return capitalize(value);
            }
            if (key === "invitationDate") {
                if (!value) {
                    return this.$t("global.new");
                }
            }
            if (Array.isArray(value)) {
                if (key === "jobNames") {
                    return value.map(capitalize).join(", ");
                }
                return value.join(", ");
            }
            if (typeof value === "boolean") {
                return value ? this.$t("global.yes") : this.$t("global.no");
            }
            if (typeof value === "number" && this.isDateCell(key)) {
                return moment(value).format(<string>this.$t("date.format"));
            }
            if (key === "fillingRate") {
                return value + "%";
            }
            return value;
        };
    }

    onModifier(modifiers: any) {
        if (this.timeout && !modifiers) {
            clearTimeout(this.timeout);
        }
        if (modifiers.sort) {
            this.sortModifiers = modifiers.sort;
        }

        this.timeout = setTimeout(() => {
            this.init(modifiers);
        }, 300);
    }

    lineClicked(row: any) {
        const target: any = row.event?.target;
        if (target) {
            if (target.classList.contains("vgt-checkbox-col") || (target.tagName.toLowerCase() === "input" && target.type === "checkbox")) {
                return;
            }
        }

        if (row.row.status === CandidateStatus.integrated && row.row.workerId) {
            this.$router.push({ name: ROUTES.APP.INTERIM, params: { interimId: row.row.workerId } });
        } else {
            this.$router.push({ name: ROUTES.ATS.CANDIDATE._ROOT, params: { candidateId: row.row._id } });
        }
    }

    candidateChanged() {
        this.init();
    }

    onSelectedRowsChange(data: any) {
        this.selectedCandidates = data.selectedRows;
    }

    async init(modifiers?: any) {
        this.loading = true;
        try {
            if (modifiers) {
                this.pagination = {
                    ...this.pagination,
                    offset: (modifiers.currentPage - 1) * modifiers.currentPerPage,
                    currentPage: modifiers.currentPage,
                    currentPerPage: modifiers.currentPerPage,
                    limit: modifiers.currentPerPage,
                    sort: modifiers.sort || this.sortModifiers,
                };
            }

            const res = await this.getCandidates({
                ...this.visualisations,
                ...this.filters,
                ...this.pagination,
            });
            this.candidates = res.rows;
            this.columns = res.columns;

            if (!modifiers) {
                this.pagination = {
                    ...res.pagination,
                    currentPage: 1,
                    currentPerPage: 50,
                };
            }
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    onRowStyleClass(row: any) {
        if (row.vgtSelected) return "selected";
    }

    beforeMount() {
        this.init();
    }
}
